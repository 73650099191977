import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import { AuthContextProvider } from './components/common/auth';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => {
  return (
    <AuthContextProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </AuthContextProvider>
  );
};

export default App;
